@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@deriv-com/blocks/style.css';
@import 'swiper/css';
@import '@deriv/quill-design/dist/quill-design.css';
@import '@deriv/quill-design/quill-tailwind/styles.css';
@import '@deriv/quill-design/quill-tailwind/fonts.css';

.heroImage {
    @apply absolute inset-50 z-10 max-lg:right-[-15vh] max-sm:left-[6vw] max-sm:w-[54vh] sm:left-auto sm:z-[15]
}

.heroAward {
    @apply w-[100px] h-[45px] sm:w-[138px] sm:h-[68px]
}

.simpleStepsNonContent {
    @apply !max-h-none justify-start
}